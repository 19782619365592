<template>
    <div class="header">
        <div class="header-left">
			<div class="back"  @click="back" v-if="isMobileFlag"><span class="el-icon-arrow-left"></span></div>
			<img src="@/assets/images/BuyGiftCard.png"  />
			  <el-popover
			     v-if="$route.name == 'giftCardList' && isMobileFlag"
			    placement="top-start"
			    width="150"
			    trigger="click"
			    >
				<div >
					<el-button type="text" @click="goHistory">Historical Orders</el-button>
				</div>
				<span class="el-icon-more more" slot="reference"></span>
			  </el-popover>
		</div>
		<div class="header-right">
			<el-button  class="history" type="primary" round v-if="$route.name == 'giftCardList'" @click="goHistory">
				<img src="@/assets/images/6156.png"  />
				Historical Orders
			</el-button>
			<div class="back" v-if="$route.name == 'historicalOrders'" @click="back">
				<img src="@/assets/images/6186.png"  />
				<span style="margin-left: 5px;">Return</span>
			</div>
		</div>
    </div>
</template>
<script>
import { isMobile } from '@/utils/index.js'
export default {
    name:'commonHeader',
    data(){
        return{
            isMobileFlag:false
        }
    },
    props: {
        title: String
    },
    mounted(){
        console.log(this.$router)
		this.isMobileFlag = isMobile()
    },
    methods: {
        back() {
            this.$router.back()
        },
		goHistory(){
			
			this.$router.push('historicalOrders')
		}
    }
}
</script>
<style scoped lang="scss">
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 0 0 54px;
	height: 68px;
	border: 2px solid #FFFFFF;
	&-right {
		display: flex;
		width: 500px;
		height: 100%;
		flex: 1;
		background: var(--linearGradient);
		padding-right: 70px;
		justify-content:flex-end;
		align-items: center;
		.history {
			
		}
		::v-deep .el-button {
			height: 48px;
			width: 230px;
			border-radius: 40px;
			display:flex;
			font-weight: 400;
			font-size: 20px;
			color: #111111;
			background: var(--Color) !important;
			padding: 0 23px;
			 >span {
				display: flex;
				align-items: center;
				line-height: 48px;
				img {
					margin-right: 5px;
				}
			}
		}
		.back {
			font-family: PingFang SC, PingFang SC;
			font-weight: bold;
			font-size: 25px;
			color: #333333;
			display: flex;
			align-items: center;
		}
		
	}

   
}
</style>