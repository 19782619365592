import request from '@/utils/request'


// post 传参用data， get传参用params
export function auth(data) {
    return request({
        url: '/cashier/gift/widget/auth',
        method: 'post',
        data
    })
}
export function getGoodsAndSendEmail(data) {
    return request({
        url: '/cashier/gift/widget/getGoodsAndSendEmail',
        method: 'post',
        data
    })
}

export function giftCardPlaceOrder(data) {
    return request({
        url: '/cashier/gift/widget/giftCardPlaceOrder',
        method: 'post',
        data
    })
}

export function getPendingOrders(params) {
    return request({
        url: '/cashier/gift/widget/getPendingOrders',
        method: 'get',
        params
    })
}

export function cancelOrder(data) {
    return request({
        url: '/cashier/gift/widget/cancelOrder',
        method: 'post',
        data
    })
}



export function category(params) {
	return request({
	    url: '/cashier/gift/widget/Category',
	    method: 'get',
	    params
	})
}
export function cryptoSupport(params) {
	return request({
	    url: '/cashier/gift/widget/cryptoSupport',
	    method: 'get',
	    params
	})
}


export function countries(params) {
	return request({
	    url: '/cashier/gift/widget/Countries',
	    method: 'get',
	    params
	})
}

export function getGoodsList (params) {
	return request({
	    url: '/cashier/gift/widget/goodsList',
	    method: 'get',
	    params
	})
}
export function paymentAddress (params) {
	return request({
	    url: '/cashier/gift/widget/paymentAddress',
	    method: 'get',
	    params
	})
}

export function giftCardDetail (params) {
	return request({
	    url: '/cashier/gift/widget/giftGoodsDetailAndSameList',
	    method: 'get',
	    params
	})
}

export function getCryptoAndNetwork (params) {
	return request({
	    url: '/cashier/gift/widget/getCryptoAndNetwork',
	    method: 'get',
	    params
	})
}


export function getHistoryOrderDetail (params) {
	return request({
	    url: '/cashier/gift/widget/getHistoryOrderDetail',
	    method: 'get',
	    params
	})
}


export function sysConfig(params) {
	return request({
	    url: '/cashier/gift/widget/sysConfig',
	    method: 'get',
	    params
	})
}

export function getCashierOrders(params) {
	return request({
	    url: 'cashier/widget/cashierOrder/pageQuery',
	    method: 'get',
	    params
	})
}

export function getConvertOrders(params) {
	return request({
	    url: 'cashier/widget/convertOrder/pageQuery',
	    method: 'get',
	    params
	})
}

export function getFiatConfig(params) {
	return request({
	    url: 'cashier/widget/wdConfig',
	    method: 'get',
	    params
	})
}

export function getQuote(params) {
	return request({
	    url: 'cashier/widget/convert/getQuote',
	    method: 'get',
	    params
	})
}


export function wdConfig(params) {
	return request({
	    url: 'cashier/widget/wdConfig',
	    method: 'get',
	    params
	})
}

export function paymentMethods(params) {
	return request({
	    url: 'cashier/widget/paymentMethods',
	    method: 'get',
	    params
	})
}

export function balances(params) {
	return request({
	    url: 'cashier/widget/convert/balances',
	    method: 'get',
	    params
	})
}

export function placeOrder(data) {
    return request({
        url: 'cashier/widget/convert/placeOrder',
        method: 'post',
        data
    })
	
}
export function deposit(data) {
    return request({
        url: 'cashier/widget/cashierOrder/deposit',
        method: 'post',
        data
    })
}

export function withdraw(data) {
    return request({
        url: 'cashier/widget/cashierOrder/withdraw',
        method: 'post',
        data
    })
}